<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Info-gradient</title>
    <path
      d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
      fill="#6E19F0"
    />
    <path
      d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
      fill="url(#paint0_linear_8015_768)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8015_768"
        x1="16.3289"
        y1="-3.96795e-07"
        x2="6.78779"
        y2="21.3685"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ic-info-gradient',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
